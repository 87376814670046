<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation class="mt-6">
      <v-row>
        <v-col cols="3" sm="3" md="3">
          <listitems
            :title="'Simulations'"
            :list="simulations"
            :headers="sheaders"
            :add="true"
            :del="true"
            :qDelete="Qsimulationdelete"
            :showstd="false"
            :Get_suf="'sel_simulation'"
            :master="false"
            :key="skey"
            :hdf="true"
            :Update="true"
            :showedit="false"
            :groupby="'intitule'"
            @open="RowAdd"
            @rowselect="RowSelect"
          >
          </listitems>
        </v-col>
        <v-col cols="9" sm="9" md="9">
          <v-row>
            <v-col cols="12" sm="12" md="12" v-if="cout_revient || state == 1">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-chip color="blue" label text-color="white" large>
                        <h3>
                          {{
                            "Coût de revient " +
                            (cout_revient
                              ? numberWithSpace(cout_revient) + " (DA/ML)"
                              : "")
                          }}
                        </h3>
                      </v-chip>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <currencyinput
                        class="mt-2"
                        label="Marge bénéficiaire (%)"
                        v-model.number="marge"
                        @input="marge_change"
                        :key="mk3"
                      />
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <currencyinput
                        class="mt-2"
                        label="Prix de Vente (DA/ML)"
                        v-model.number="prixvente"
                        :key="mk4"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="2" md="2">
                      {{ "Unité de Fabrication : " + unite_name }}
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      {{
                        "∅ " +
                        diametre_inch +
                        '" Epaiss. ' +
                        epaisseur_mm +
                        " mm "
                      }}
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      {{
                        " Mise au Mille " +
                        this.mam +
                        "-Largeur Bande " +
                        this.largeur_mm +
                        " mm"
                      }}
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      {{ " Linèaire " + this.linaire_ml + " ML" }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-card flat>
                <v-tabs>
                  <v-tab> <v-icon left> mdi-web </v-icon>Input </v-tab>
                  <v-tab v-if="unite">
                    <v-icon left> mdi-ubisoft </v-icon>Bobines
                  </v-tab>
                  <v-tab v-if="unite">
                    <v-icon left> mdi-file-document-multiple-outline </v-icon
                    >Intrants
                  </v-tab>
                  <v-tab v-if="unite">
                    <v-icon left> mdi-weight-kilogram </v-icon>Ratios
                  </v-tab>
                  <v-tab v-if="unite">
                    <v-icon left> mdi-store </v-icon>Stocks
                  </v-tab>
                  <v-tab v-if="unite">
                    <v-icon left> mdi-cash-100 </v-icon> Frais
                  </v-tab>
                  <v-tab v-if="unite">
                    <v-icon left> mdi-factory </v-icon>Machines
                  </v-tab>
                  <v-tab v-if="unite">
                    <v-icon left> mdi-cash </v-icon>Cautions
                  </v-tab>
                  <v-tab v-if="unite">
                    <v-icon left> mdi-playlist-check </v-icon>Besoins
                  </v-tab>

                  <v-tab-item>
                    <v-card :disabled="state == 1">
                      <v-card-text>
                        <v-row>
                          <v-col cols="6" sm="6" md="6">
                            <v-select
                              label="Intitule du Projet"
                              v-model="project_name"
                              :item-value="'intitule'"
                              :item-text="'intitule'"
                              :items="projects"
                              dense
                            ></v-select>
                          </v-col>
                          <v-col cols="6" sm="1" md="1">
                            <v-btn color="blue darken-1" @click="add_project">
                              +
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" lg="1" md="1" sm="1">
                            <v-checkbox
                              v-model="bobine"
                              label="Bobines"
                              @change="data_change"
                              dense
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" lg="1" md="1" sm="1">
                            <v-checkbox
                              v-model="fil_flux"
                              label="Fils/Flux"
                              @change="data_change"
                              dense
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" lg="1" md="1" sm="1">
                            <v-checkbox
                              v-model="ext"
                              label="Rev. Ext."
                              @change="data_change"
                              dense
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" lg="1" md="1" sm="1">
                            <v-checkbox
                              v-model="int"
                              label="Rev. Int."
                              @change="data_change"
                              dense
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" lg="2" md="2" sm="2">
                            <v-checkbox
                              v-model="transport_tube"
                              label="Transport Tube"
                              @change="data_change"
                              dense
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" lg="2" sm="2" md="2">
                            <v-select
                              v-model="secteur"
                              :items="[
                                { id: 1, label: 'Hydrocarbure' },
                                { id: 2, label: 'Hydraulique' },
                                { id: 3, label: 'Gaine' },
                              ]"
                              :item-value="'id'"
                              :item-text="'label'"
                              dense
                              outlined
                              label="Secteur d'utilisation"
                              @change="secteur_change"
                              :rules="[(v) => !!v || 'Utilisation obligatoire']"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" lg="2" sm="2" md="2">
                            <v-select
                              v-model="unite"
                              :items="[
                                { id: 1, label: 'ANNABA' },
                                { id: 2, label: 'GHARDAIA' },
                                { id: 3, label: 'ORAN' },
                              ]"
                              :item-value="'id'"
                              :item-text="'label'"
                              dense
                              outlined
                              label="Unite de Fabrication"
                              :rules="[
                                (v) =>
                                  !!v || 'Unite de Fabrication obligatoire',
                              ]"
                              @change="unite_change"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="1" md="1">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="diametre_inch"
                              label="∅ (Inch)"
                              dense
                              @input="diametre_inch_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="1" md="1">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="diametre_mm"
                              label="∅ (mm)"
                              dense
                              @input="diametre_mm_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="2">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="epaisseur_mm"
                              label="Epaisseur (mm)"
                              dense
                              :rules="[
                                (v) =>
                                  !v ||
                                  (v >= min_epais && v <= max_epais) ||
                                  !min_epais ||
                                  !max_epais ||
                                  'Erreur Epaisseur',
                              ]"
                              @input="epais_change"
                              :disabled="!diametre_inch"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="2">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="largeur_mm"
                              label="Largeur Bande (mm)"
                              dense
                              @input="largeur_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="2">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="linaire_ml"
                              label="Linéaire (ML)"
                              dense
                              @input="linaire_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="1" md="1">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="mam"
                              label="Mise Au Mille"
                              dense
                              @input="data_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="1" md="1">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              v-model.number="delai"
                              @input="delai_change"
                              label="Délai (Mois)"
                              dense
                              outlined
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="1" md="1">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              v-model.number="delai2"
                              label="Délai  calculé"
                              dense
                              :disabled="true"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="2"
                            sm="2"
                            md="2"
                            v-if="cout_revient && state == 0 && project_name"
                          >
                            <v-btn
                              color="blue darken-1"
                              @click="save_simulation"
                            >
                              <v-icon left> mdi-content-save </v-icon>
                              Enregistrer
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card elevation="10" class="ma-5" :disabled="state == 1">
                      <v-card-title
                        >Bobines
                        <v-spacer></v-spacer>
                        <v-checkbox
                          v-model="local"
                          label="Acier local"
                          @change="local_change"
                          dense
                        ></v-checkbox>
                      </v-card-title>
                      <!-- <v-img
											height="250"
											src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
										></v-img> -->
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" lg="2" sm="2" md="2">
                            <currencyinput
                              :label="'Acier (' + money + '/Tonne)'"
                              v-model.number="cout_acier"
                              @input="data_change"
                              :key="mk"
                            />
                          </v-col>
                          <v-col cols="12" lg="2" sm="2" md="2" v-if="!local">
                            <v-select
                              v-model="money"
                              :items="['Euro', 'Dollar']"
                              dense
                              outlined
                              label="Devise"
                              @change="devise_change"
                            ></v-select>
                          </v-col>

                          <v-col cols="12" lg="2" sm="2" md="2" v-if="!local">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="taux_change"
                              :label="'Taux de change (DA/' + money + ')'"
                              dense
                              @input="data_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="2">
                            <currencyinput
                              label="Poids Bobines (Tn)"
                              v-model.number="poids"
                              @input="data_change"
                              :key="mk"
                            />
                          </v-col>
                          <v-col cols="12" sm="2" md="2" v-if="!local">
                            <currencyinput
                              label="Frais Magasinage (%)"
                              v-model.number="taux_mag"
                              @input="data_change"
                              :key="mk"
                            />
                          </v-col>
                          <v-col cols="12" sm="2" md="2" v-if="!local">
                            <currencyinput
                              label="Droit Douane (%)"
                              v-model.number="taux_dd"
                              @input="data_change"
                              :key="mk"
                            />
                          </v-col>
                          <v-col cols="12" lg="3" sm="3" md="3" v-if="!local">
                            <v-select
                              v-model="modalite"
                              :items="[
                                { id: 1, label: 'Lettre de credit' },
                                { id: 2, label: 'Remise documentaire' },
                                {
                                  id: 3,
                                  label: 'Remise documentaire avec délai',
                                },
                              ]"
                              :item-value="'id'"
                              :item-text="'label'"
                              dense
                              outlined
                              label="Modalité de Paiement"
                              :rules="[
                                (v) =>
                                  !!v || 'Modalité de Paiement obligatoire',
                              ]"
                              @change="mk++"
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="2"
                            md="2"
                            v-if="!local && modalite == 1"
                          >
                            <currencyinput
                              label="Frais LC (%)"
                              v-model.number="taux_lc"
                              @input="data_change"
                              :key="mk"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            sm="2"
                            md="2"
                            v-if="!local && modalite == 3"
                          >
                            <currencyinput
                              label="Délai Remise (Mois)"
                              v-model.number="delai_remise"
                              @input="data_change"
                              :rules="[
                                (v) => !!v || 'Délai Remise obligatoire',
                              ]"
                              :key="mk"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card elevation="10" class="ma-5" :disabled="state == 1">
                      <v-card-title>Intrants</v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="2"
                            md="2"
                            v-for="cout in couts.filter(
                              (elm) =>
                                elm.secteur == secteur || elm.secteur == 4
                            )"
                            :key="cout.id"
                          >
                            <currencyinput
                              class="mt-n2"
                              :label="cout.label + ' DA/Tn'"
                              v-model.number="cout.cout"
                              @input="data_change"
                              :key="mk"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            lg="2"
                            sm="2"
                            md="2"
                            v-if="unite && $store.state.isadmin"
                          >
                            <v-btn
                              color="blue darken-1"
                              @click="update_couts"
                              :disabled="!couts_changed || state == 1"
                            >
                              <v-icon left> mdi-content-save </v-icon>
                              Enregistrer
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card elevation="10" class="ma-5" :disabled="state == 1">
                      <v-card-subtitle>Ratios</v-card-subtitle>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="2"
                            md="2"
                            v-for="ratio in ratios.filter(
                              (elm) =>
                                elm.secteur == secteur || elm.secteur == 4
                            )"
                            :key="ratio.id"
                          >
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="ratio.value"
                              :label="ratio.label"
                              dense
                              @input="data_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="2"
                            sm="2"
                            md="2"
                            v-if="unite && $store.state.isadmin"
                          >
                            <v-btn
                              color="blue darken-1"
                              @click="update_ratios"
                              :disabled="!ratios_changed || state == 1"
                            >
                              <v-icon left> mdi-content-save </v-icon>
                              Enregistrer
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card elevation="10" class="ma-5" :disabled="state == 1">
                      <v-card-subtitle>Stock</v-card-subtitle>
                      <v-card-text>
                        <v-row
                          v-for="stock in stocks.filter(
                            (elm) => elm.secteur == secteur || elm.secteur == 4
                          )"
                          :key="stock.id"
                        >
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="stock.value"
                              :label="stock.label"
                              dense
                              @input="data_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="stock.cout"
                              :label="'Coût'"
                              dense
                              @input="data_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            lg="2"
                            sm="2"
                            md="2"
                            v-if="unite && $store.state.isadmin"
                          >
                            <v-btn
                              color="blue darken-1"
                              @click="update_stocks"
                              :disabled="!stocks_changed || state == 1"
                            >
                              <v-icon left> mdi-content-save </v-icon>
                              Enregistrer
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card elevation="10" class="ma-5" :disabled="state == 1">
                      <v-card-title>Frais</v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="2"
                            md="2"
                            v-for="fr in frais.filter((elm) => elm.selected)"
                            :key="fr.id"
                          >
                            <currencyinput
                              class="mt-n2"
                              :label="fr.label"
                              v-model.number="fr.value"
                              @input="data_change"
                              :key="mk"
                            />
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <currencyinput
                              label="Frais Ligne de credit (%)"
                              v-model.number="taux_credit"
                              @input="data_change"
                              :key="mk"
                            />
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <currencyinput
                              label="Mobilisation (Mois)"
                              v-model.number="mois_mobilis"
                              @input="data_change"
                              :key="mk"
                            />
                          </v-col>
                          <v-col cols="12" sm="4" md="4" v-if="transport_tube">
                            <currencyinput
                              label="Transport Tube (DA/ML)"
                              v-model.number="cout_transport_tube"
                              @input="data_change"
                              :key="mk"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            lg="2"
                            sm="2"
                            md="2"
                            v-if="unite && $store.state.isadmin"
                          >
                            <v-btn
                              color="blue darken-1"
                              @click="update_frais"
                              :disabled="!frais_changed || state == 1"
                            >
                              <v-icon left> mdi-content-save </v-icon>
                              Enregistrer
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card elevation="10" class="ma-5" :disabled="state == 1">
                      <v-card-title>Machines</v-card-title>
                      <v-card-text>
                        <v-row
                          v-for="machine in machines"
                          :key="machine.id"
                          no-gutters
                        >
                          <v-col cols="12" sd="1" md="1">
                            <v-checkbox
                              v-model="machine.product"
                              dense
                              @change="data_change"
                              :disabled="machine.product_disable"
                            ></v-checkbox>
                          </v-col>

                          <v-col cols="12" sd="1" md="1" class="mx-1">
                            <v-text-field
                              v-model="machine.label"
                              dense
                              readonly
                              outlined
                              :disabled="!machine.product"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sd="1" md="1" class="mx-1">
                            <v-text-field
                              v-model="machine.vitesse"
                              dense
                              label="Vitesse (ML/Minute)"
                              outlined
                              @input="vitesse_change(machine)"
                              :disabled="!machine.product"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sd="1" md="1" class="mx-1">
                            <v-text-field
                              v-model="machine.cadense"
                              dense
                              readonly
                              label="Cadense (ML/Heure)"
                              outlined
                              :disabled="!machine.product"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sd="1" md="1" class="mx-1">
                            <v-text-field
                              v-model="machine.taux_marche"
                              dense
                              label="Taux Marche (%)"
                              outlined
                              @input="data_change"
                              :disabled="!machine.product"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sd="1" md="1" class="mx-1">
                            <v-text-field
                              v-model="machine.poste"
                              dense
                              readonly
                              label="ML/Poste"
                              outlined
                              :disabled="!machine.product"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sd="1" md="1" class="mx-1">
                            <v-select
                              v-model="machine.postes"
                              outlined
                              @input="data_change"
                              :disabled="!machine.product"
                              dense
                              label="Shift"
                              :items="[1, 2, 3]"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" sd="1" md="1" class="mx-1">
                            <v-text-field
                              v-model="machine.jour"
                              dense
                              readonly
                              label="ML/Jour"
                              outlined
                              :disabled="!machine.product"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sd="1" md="1" class="mx-1">
                            <v-checkbox
                              v-model="machine.continu"
                              label="Continu"
                              dense
                              :disabled="!machine.product"
                              @change="data_change"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sd="1" md="1" class="mx-1">
                            <v-text-field
                              v-model="machine.postes_mois"
                              dense
                              label="Postes/Mois"
                              outlined
                              :disabled="!machine.product"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sd="1" md="1" class="mx-1">
                            <v-text-field
                              v-model="machine.product_mois"
                              dense
                              label="ML/Mois"
                              outlined
                              :disabled="!machine.product"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sd="2" md="2" class="mx-2">
                            <h2>Cumul Mois</h2>
                          </v-col>
                          <v-col cols="12" sd="2" md="2" class="mx-2">
                            <h2>
                              {{
                                numberWithSpace(total_postes_mois) + " Postes"
                              }}
                            </h2>
                          </v-col>
                          <v-col cols="12" sd="2" md="2" class="mx-2">
                            <h2>
                              {{
                                numberWithSpace(total_product_mois.toFixed(2)) +
                                " ML"
                              }}
                            </h2>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="2"
                            sm="2"
                            md="2"
                            v-if="unite && $store.state.isadmin"
                          >
                            <v-btn
                              color="blue darken-1"
                              @click="update_machines"
                              :disabled="!machines_changed || state == 1"
                            >
                              <v-icon left> mdi-content-save </v-icon>
                              Enregistrer
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card elevation="10" class="ma-5" :disabled="state == 1">
                      <v-card-title>Cautions</v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="2" md="2">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="caut_soumis"
                              label="Caution de Soumission(%)"
                              dense
                              @input="delai_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="2">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="caut_rest_av"
                              label="Caution de Restitution Avance(%)"
                              dense
                              @input="delai_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="2">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="caut_bon_exe"
                              label="Caution de Bonne Execution(%)"
                              dense
                              @input="delai_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="2">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="caut_pen_ret"
                              label="Caution de Penalités de retard(%)"
                              dense
                              @input="delai_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="2"
                            md="2"
                            v-if="caut_pen_ret > 0"
                          >
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              outlined
                              v-model.number="nb_trimest"
                              label="Nb Trimestre"
                              dense
                              @input="delai_change"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card elevation="10" class="ma-5" :disabled="state == 1">
                      <v-card-title>
                        Quantité
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <v-card-subtitle
                        >{{
                          poids_ml > 0
                            ? "Poids Métrique : " + poids_ml + " T/ML"
                            : ""
                        }}
                      </v-card-subtitle>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <listitems
                              :list="intrants_list"
                              :title="'Quantité'"
                              :headers="headers"
                              :filename="
                                project_name
                                  ? project_name +
                                    '_' +
                                    diametre_inch +
                                    '_' +
                                    epaisseur_mm +
                                    '_' +
                                    linaire_ml
                                  : diametre_inch +
                                    '_' +
                                    epaisseur_mm +
                                    '_' +
                                    linaire_ml
                              "
                              :sheetname="'Besoins'"
                              :Total="true"
                              :add="false"
                              :del="false"
                              :key="klist"
                              :ipg="-1"
                              :hdf="true"
                            >
                            </listitems>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <v-dialog width="600" v-model="isNew_project">
      <template>
        <v-card title="Nouveau projet">
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="12" md="12"> Projet: </v-col>
              <v-col cols="12" sm="10" md="10">
                <v-text-field
                  dense
                  outlined
                  v-model="project_name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" @click="save_project">Ajouter</v-btn>
            <v-btn color="blue darken-1" @click="close_project">Annuler</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}
import PRODUCTION_UNIT from "../graphql/Simulat/PRODUCTION_UNIT.gql";
import UPSERT_PRODUCTION_UNIT from "../graphql/Simulat/UPSERT_PRODUCTION_UNIT.gql";
import UPSERT_SIMULATION from "../graphql/Simulat/UPSERT_SIMULATION.gql";
import DELETE_SIMULATION from "../graphql/Simulat/DELETE_SIMULATION.gql";
export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    currencyinput: () => import("../components/CurrencyInput.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },

  data: () => ({
    sel_simulation: {},
    simulation: {},
    simulations: [],
    skey: 5000,
    sid: -1,
    sheaders: [
      {
        text: "Projet",
        value: "intitule",
        selected: false,
      },
      {
        text: "Date",
        value: "date",
        selected: true,
      },
      {
        text: "P. Revient",
        value: "cout_revient",
        selected: true,
      },
    ],
    isNew_project: false,
    project_name: null,
    projects: [],
    state: 0,
    loading: false,
    valid: true,
    mk: 100,
    mk2: 200,
    mk3: 300,
    mk4: 600,
    klist: 400,
    kart: 500,
    agent: {},
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    headers: [
      {
        text: "No.",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Libelle",
        value: "label",
        selected: true,
      },
      {
        text: "Type",
        value: "type",
        slot: "chip",
        color: "color",
        selected: true,
      },
      {
        text: "Quantité",
        value: "quantite",
        slot: "cur",
        align: "right",
        selected: true,
      },
      {
        text: "Unite",
        value: "unit",
        selected: true,
      },
      {
        text: "Ratio",
        value: "ratio",
        selected: true,
      },
      {
        text: "Coût",
        value: "cout",
        slot: "cur",
        align: "right",
        selected: true,
      },
      {
        text: "Montant",
        value: "montant",
        slot: "cur",
        selected: true,
        align: "right",
        total: "montant",
      },
    ],
    caut_soumis: 0,
    caut_rest_av: 0,
    caut_bon_exe: 0,
    caut_pen_ret: 0,
    nb_trimest: 1,
    money: "Euro",
    intrants_list: [],
    machines: [],
    machines_changed: false,
    delai_changed: false,
    max_epais: null,
    min_epais: null,
    vitesses1: [],
    couts_changed: false,
    secteur: null,
    unite: null,
    diametre_inch: null,
    diametre_mm: null,
    epaisseur_mm: null,
    largeur_mm: 1700,
    poids_ml: null,
    linaire_ml: null,
    ratios: [],
    ratios_changed: false,
    stocks: [],
    stocks_changed: false,
    couts: [],
    cout_acier: 700,
    poids: 30,
    mam: 1080,
    unite_name: null,
    unite_code: null,
    taux_change: 146.226,
    modalite: 1,
    delai_remise: 3,
    taux_lc: 0.65,
    taux_dd: 5,
    taux_mag: 3.0,
    taux_credit: 7.5,
    mois_mobilis: 3,
    frais: [],
    frais_changed: false,
    cout_transport_tube: 0,
    transport_tube: false,
    art_loading: false,
    bobine: true,
    local: false,
    fil_flux: true,
    delai: null,
    delai2: null,
    ext: true,
    int: true,
    marge: 10,
    prixvente: null,
    cout_revient: null,
    total_postes_mois: 0,
    total_product_mois: 0,
    production_units: [],
  }),
  watch: {
    frais: {
      handler() {
        this.frais_changed = true;
      },
      deep: true,
    },
    couts: {
      handler() {
        this.couts_changed = true;
      },
      deep: true,
    },
    ratios: {
      handler() {
        this.ratios_changed = true;
      },
      deep: true,
    },
    stocks: {
      handler() {
        this.stocks_changed = true;
      },
      deep: true,
    },
    machines: {
      handler() {
        this.machines_changed = true;
      },
      deep: true,
    },
  },
  computed: {
    Qsimulationdelete() {
      return DELETE_SIMULATION;
    },
  },
  created() {
    this.get_data();
  },

  mounted() {},

  methods: {
    async get_data() {
      let r = await this.requette(PRODUCTION_UNIT);
      if (r) {
        this.production_units = r.production_units;
        this.simulations = r.simulation;
        this.projects = r.simulation_projects;
        this.skey++;
      }
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async update_ratios() {
      await this.maj(UPSERT_PRODUCTION_UNIT, {
        id: this.unite,
        ratios: JSON.stringify(this.ratios),
      });
      this.ratios_changed = false;
    },
    async update_stocks() {
      await this.maj(UPSERT_PRODUCTION_UNIT, {
        id: this.unite,
        stocks: JSON.stringify(this.stocks),
      });
      this.stocks_changed = false;
    },
    async update_frais() {
      await this.maj(UPSERT_PRODUCTION_UNIT, {
        id: this.unite,
        frais: JSON.stringify(this.frais),
      });
      this.frais_changed = false;
    },
    async update_couts() {
      await this.maj(UPSERT_PRODUCTION_UNIT, {
        id: this.unite,
        couts: JSON.stringify(this.couts),
      });
      this.couts_changed = false;
    },
    async update_machines() {
      await this.maj(UPSERT_PRODUCTION_UNIT, {
        id: this.unite,
        machines: JSON.stringify(this.machines),
      });
      this.machines_changed = false;
    },
    async update_data() {},
    devise_change() {
      if (this.money == "Euro") {
        this.taux_change = 146.226;
      } else {
        this.taux_change = 135.25;
      }
      this.mk++;
      this.data_change();
    },
    async vitesse_change(item) {
      item.vitesse_changed = true;
      await this.data_change();
      //item.vitesse_changed = false;
    },
    async delai_change() {
      this.delai_changed = true;
      await this.data_change();
      this.delai_changed = false;
    },
    local_change() {
      if (this.local) {
        this.money = "DA";
        this.cout_acier = 95000;
        this.largeur_mm = 1200;
        this.poids = 19;
        this.taux_dd = 0;
      } else {
        this.money = "Euro";
        this.cout_acier = 700;
        this.poids = 30;
        if (this.secteur == 1) this.taux_dd = 0;
        else this.taux_dd = 5;
        this.largeur_limit();
      }
      this.mk++;
      this.data_change();
    },
    epais_limit() {
      if (this.diametre_inch >= 16 && this.diametre_inch <= 22) {
        this.min_epais = 6;
        this.max_epais = 11.91;
      }
      if (this.diametre_inch > 22 && this.diametre_inch <= 24) {
        this.min_epais = 6;
        this.max_epais = 13.4;
      }
      if (this.diametre_inch > 24 && this.diametre_inch <= 34) {
        this.min_epais = 6;
        this.max_epais = 15;
      }
      if (this.diametre_inch > 34 && this.diametre_inch <= 48) {
        this.min_epais = 7.92;
        this.max_epais = 17;
      }
      if (this.diametre_inch > 48 && this.diametre_inch <= 80) {
        this.min_epais = 7.92;
        this.max_epais = 25.4;
      }
    },
    largeur_limit() {
      if (this.local) this.largeur_mm = 1200;
      else if (this.diametre_inch <= 24 && this.largeur_mm > 1200)
        this.largeur_mm = 1200;
      else if (
        this.diametre_inch > 24 &&
        this.diametre_inch <= 30 &&
        (this.largeur_mm < 1200 || this.largeur_mm > 1400)
      )
        this.largeur_mm = 1400;
      else if (this.diametre_inch > 30 && this.largeur_mm < 1700)
        this.largeur_mm = 1700;
    },
    diametre_inch_change() {
      this.diametre_mm = (this.diametre_inch * 25.4).toFixed(2);
      this.delai = null;
      this.largeur_limit();
      this.epais_limit();
      this.data_change();
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    diametre_mm_change() {
      this.diametre_inch = (this.diametre_mm / 25.4).toFixed(2);
      this.delai = null;
      this.largeur_limit();
      this.epais_limit();
      this.data_change();
    },
    epais_change() {
      this.delai = null;
      this.data_change();
    },
    largeur_change() {
      this.delai = null;
      this.data_change();
    },
    linaire_change() {
      this.delai = null;
      this.data_change();
    },
    secteur_change() {
      this.int = true;
      this.ext = true;
      this.taux_dd = 0;

      if (this.secteur == 1) {
        this.mam = 1130;
        this.taux_dd = 0;
      } else if (this.secteur == 2) {
        this.mam = 1080;
        if (!this.local) this.taux_dd = 5;
      } else if (this.secteur == 3) {
        this.mam = 1040;
        this.int = false;
        this.ext = false;
        if (!this.local) this.taux_dd = 5;
      }
      this.data_change();
      this.mk4++;
    },
    unite_change() {
      this.machines = [];

      let i = this.production_units.findIndex((elm) => elm.id == this.unite);
      if (i >= 0) {
        this.unite_code = this.production_units[i].unit_code;
        this.unite_name = this.production_units[i].unit_name;
        this.ratios = JSON.parse(this.production_units[i].ratios);
        this.machines = JSON.parse(this.production_units[i].machines);
        this.frais = JSON.parse(this.production_units[i].frais);
        this.couts = JSON.parse(this.production_units[i].couts);
        this.stocks = JSON.parse(this.production_units[i].stocks);

        this.vitesses1 = JSON.parse(
          JSON.parse(this.production_units[i].vitesses1)
        );
        this.vitesses2 = JSON.parse(this.production_units[i].vitesses2);
        this.$nextTick(() => {
          this.ratios_changed = false;
          this.machines_changed = false;
          this.frais_changed = false;
          this.couts_changed = false;
          this.stocks_changed = false;
        });
        this.cout_transport_tube = 0;

        let j = this.frais.findIndex((elm) => elm.id == "transp_tube");
        if (j >= 0) this.cout_transport_tube = this.frais[j].value;
      }

      this.mk++;
      this.data_change();
      this.mk4++;
    },
    marge_change() {
      if (this.cout_revient > 0)
        this.prixvente = (
          this.cout_revient *
          (1 + this.marge && this.marge > 0 ? this.marge : 10 / 100)
        ).toFixed(2);
      this.mk4++;
    },
    pv_change() {
      if (this.cout_revient > 0)
        this.marge = ((this.prixvente / this.cout_revient) * 100).toFixed(2);
      //this.mk3++;
    },

    data_change: debounce(function () {
      this.data2_change();
    }, 500),
    data2_change() {
      //if (this.state == 0)
      {
        this.$refs.form.validate();
        this.intrants_list = [];
        this.cout_revient = 0;
        let a = this.diametre_mm / 2;
        let b = this.diametre_mm / 2 - this.epaisseur_mm;
        let poids;
        if (this.epaisseur_mm && this.diametre_mm) {
          poids =
            ((Math.pow(a, 2) - Math.pow(b, 2)) * Math.PI * 7850) / 1000000000;
          this.poids_ml = poids.toFixed(3);
          //cadense
          this.machines.forEach((element) => {
            if (
              !element.vitesse_changed ||
              element.vitesse_changed == "undefined"
            )
              element.vitesse = 0;
            element.cadense = 0;
            element.poste = 0;
            element.jour = 0;
            element.postes_mois = 0;
            element.product_mois = 0;
            element.product_disable = false;

            if (
              element.diam_min <= this.diametre_inch &&
              element.diam_max >= this.diametre_inch &&
              element.epais_min <= this.epaisseur_mm &&
              element.epais_max >= this.epaisseur_mm &&
              element.larg_max >= this.largeur_mm
            ) {
              if (element.product) {
                let l = [];
                if (element.id != "E") l = this.vitesses1;
                else l = this.vitesses2;
                let i = l.findIndex(
                  (elm) =>
                    elm.epais_min < this.epaisseur_mm &&
                    elm.epais_max >= this.epaisseur_mm
                );
                if (
                  i >= 0 &&
                  (!element.vitesse_changed ||
                    element.vitesse_changed == "undefined")
                )
                  element.vitesse = l[i].vitesse;
                element.cadense = (
                  (element.vitesse * 60 * (this.largeur_mm - 30)) /
                  1000 /
                  ((Math.PI * this.diametre_mm) / 1000)
                ).toFixed(2);
                element.poste = (
                  (parseFloat(element.cadense) * 8 * element.taux_marche) /
                  100
                ).toFixed(2);
                element.jour = (
                  parseFloat(element.poste) * element.postes
                ).toFixed(2);
                let nbjour = element.continu ? 30 : 22;

                element.postes_mois = (element.postes * nbjour).toFixed(2);
                element.product_mois = (
                  parseFloat(element.poste) * parseFloat(element.postes_mois)
                ).toFixed(2);
              }
            } else {
              element.product_disable = true;
            }
          });
          this.total_postes_mois = this.machines.reduce(
            (a, b) => a + (parseFloat(b["postes_mois"]) || 0),
            0
          );
          this.total_product_mois = this.machines.reduce(
            (a, b) => a + (parseFloat(b["product_mois"]) || 0),
            0
          );
        }

        if (
          this.linaire_ml &&
          this.total_product_mois > 0 &&
          this.secteur > 0
        ) {
          if (!this.delai_changed)
            this.delai2 = (
              (this.linaire_ml * this.mam) /
              1000 /
              this.total_product_mois
            ).toFixed(2);
          if (!this.delai) this.delai = this.delai2;
          let tot_charge = 0;
          let taux_ligne = (this.taux_credit / 100) * (this.mois_mobilis / 12);
          //bobine
          if (poids) {
            if (this.bobine) {
              let acier = (poids * this.linaire_ml * this.mam) / 1000;
              let d = 1;
              if (!this.local) d = this.taux_change * (1 + this.taux_dd / 100); // taux change + droit de douane
              let c = this.cout_acier * d;
              let st = this.stocks.findIndex((elm) => elm.id == "acier");

              let acier_ach =
                acier - this.stocks[st].value > 0
                  ? acier - this.stocks[st].value
                  : 0;
              let acier_stk = acier - acier_ach;
              this.intrants_list.push({
                id: "acier",
                label: "Bobines d'acier",
                quantite: acier,
                ratio: this.mam,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout:
                  (acier_ach * c + acier_stk * this.stocks[st].cout) / acier,
                montant: acier_ach * c + acier_stk * this.stocks[st].cout,
              });
              let mb = this.mois_mobilis;
              if (this.modalite == 3) {
                let dr = this.delai_remise ? this.delai_remise : 0;
                mb = this.mois_mobilis - dr > 0 ? this.mois_mobilis - dr : 0;
              }
              tot_charge =
                tot_charge +
                acier_ach * c * (this.taux_credit / 100) * (mb / 12);
              let j = this.frais.findIndex((elm) => elm.id == "transp_bobine");
              if (this.frais[j].value > 0) {
                let nb = Math.ceil(acier_ach / this.poids) + 1;
                this.intrants_list.push({
                  id: "transport_acier",
                  label: "Transport Bobines d'acier",
                  quantite: nb,
                  unit: "Bobine",
                  type: "Frais",
                  color: "blue",
                  cout: this.frais[j].value,
                  montant: nb * this.frais[j].value,
                });
                tot_charge = tot_charge + nb * this.frais[j].value * taux_ligne;
              }
              if (this.taux_mag > 0 && !this.local) {
                let c2 = this.cout_acier * this.taux_change;
                this.intrants_list.push({
                  id: "Frais_approche_magasinage",
                  label: "Frais Approche Acier",
                  quantite: acier_ach * c2,
                  unit: "Taux",
                  type: "Frais",
                  color: "blue",
                  cout: this.taux_mag,
                  montant: (acier_ach * c2 * this.taux_mag) / 100,
                });
                tot_charge =
                  tot_charge +
                  ((acier_ach * c2 * this.taux_mag) / 100) * taux_ligne;
              }
              if (this.taux_lc > 0 && this.modalite == 1 && !this.local) {
                let c2 = this.cout_acier * this.taux_change;
                this.intrants_list.push({
                  id: "Frais_lc",
                  label: "Frais lettre de credit",
                  quantite: acier_ach * c2,
                  unit: "Taux",
                  type: "Frais",
                  color: "blue",
                  cout: this.taux_lc,
                  montant: (acier_ach * c2 * this.taux_lc) / 100,
                });
                tot_charge =
                  tot_charge +
                  ((acier_ach * c2 * this.taux_lc) / 100) * taux_ligne;
              }
            } else {
              this.intrants_list.push({
                id: "acier",
                label: "Bobines d'acier",
                quantite: 0,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout: 0,
                montant: 0,
              });
              this.intrants_list.push({
                id: "transport_acier",
                label: "Transport Bobines d'acier",
                quantite: 0,
                unit: "Bobine",
                type: "Frais",
                color: "blue",
                cout: 0,
                montant: 0,
              });
              this.intrants_list.push({
                id: "Frais_approche_magasinage",
                label: "Frais Approche Acier",
                quantite: 0,
                unit: "Taux",
                type: "Frais",
                color: "blue",
                cout: 0,
                montant: 0,
              });
              this.intrants_list.push({
                id: "Frais_lc",
                label: "Frais lettre de credit",
                quantite: 0,
                unit: "Taux",
                type: "Frais",
                color: "blue",
                cout: 0,
                montant: 0,
              });
            }
          }
          //fils/flux
          if (this.largeur_mm > 0 && this.diametre_mm > 0) {
            if (this.fil_flux) {
              let l =
                (((Math.PI * this.diametre_mm) / (this.largeur_mm - 30)) *
                  this.linaire_ml *
                  this.mam) /
                1000;
              let j = this.ratios.findIndex((elm) => elm.id == "ratio_fil");

              if (this.ratios[j].value > 0) {
                let ratio_fil =
                  this.ratios[j].value + " " + this.ratios[j].label;
                let st = this.stocks.findIndex((elm) => elm.id == "fil");
                let fil =
                  (l * this.ratios[j].value * this.epaisseur_mm) / 12.7 / 1000;
                let fil_ach =
                  fil - this.stocks[st].value > 0
                    ? fil - this.stocks[st].value
                    : 0;
                let fil_stk = fil - fil_ach;
                let cout_fil = this.couts.find((elm) => elm.id == "fil").cout;
                this.intrants_list.push({
                  id: "fil",
                  label: "Fil à Souder",
                  quantite: fil,
                  ratio: ratio_fil,
                  unit: "Tonne",
                  type: "Matière",
                  color: "green",
                  cout:
                    (fil_ach * cout_fil + fil_stk * this.stocks[st].cout) / fil,
                  montant: fil_ach * cout_fil + fil_stk * this.stocks[st].cout,
                });
                tot_charge = tot_charge + fil_ach * cout_fil * taux_ligne;
              }
              j = this.ratios.findIndex((elm) => elm.id == "ratio_flux");
              if (this.ratios[j].value > 0) {
                let ratio_flux =
                  this.ratios[j].value + " " + this.ratios[j].label;
                let st = this.stocks.findIndex((elm) => elm.id == "flux");
                let flux = (l * this.ratios[j].value) / 1000;
                let flux_ach =
                  flux - this.stocks[st].value
                    ? flux - this.stocks[st].value
                    : 0;
                let flux_stk = flux - flux_ach;
                let cout_flux = this.couts.find((elm) => elm.id == "flux").cout;
                this.intrants_list.push({
                  id: "flux",
                  label: "Flux de soudage",
                  quantite: flux,
                  ratio: ratio_flux,
                  unit: "Tonne",
                  type: "Matière",
                  color: "green",
                  cout:
                    (flux_ach * cout_flux + flux_stk * this.stocks[st].cout) /
                    flux,
                  montant:
                    flux_ach * cout_flux + flux_stk * this.stocks[st].cout,
                });
                tot_charge = tot_charge + flux_ach * cout_flux * taux_ligne;
              }
              j = this.ratios.findIndex((elm) => elm.id == "ratio_elect");
              if (this.ratios[j].value > 0) {
                let ratio_elect =
                  this.ratios[j].value + " " + this.ratios[j].label;
                let st = this.stocks.findIndex((elm) => elm.id == "elect");
                let elect = this.linaire_ml * this.ratios[j].value;
                let elect_ach =
                  elect - this.stocks[st].value > 0
                    ? elect - this.stocks[st].value
                    : 0;
                let elect_stk = elect - elect_ach;
                let cout_elect = this.couts.find(
                  (elm) => elm.id == "elect"
                ).cout;
                this.intrants_list.push({
                  id: "elect",
                  label: "Electrode de soudage",
                  quantite: elect,
                  ratio: ratio_elect,
                  unit: "Unité",
                  type: "Matière",
                  color: "green",
                  cout:
                    (elect * cout_elect + elect_stk * this.stocks[st].cout) /
                    elect,
                  montant:
                    elect * cout_elect + elect_stk * this.stocks[st].cout,
                });
                tot_charge = tot_charge + elect_ach * cout_elect * taux_ligne;
              }
            } else {
              this.intrants_list.push({
                id: "flux",
                label: "Flux de soudage",
                quantite: 0,
                ratio: 0,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout: 0,
                montant: 0,
              });
              this.intrants_list.push({
                id: "elect",
                label: "Electrode de soudage",
                quantite: 0,
                ratio: 0,
                unit: "Unité",
                type: "Matière",
                color: "green",
                cout: 0,
                montant: 0,
              });
              this.intrants_list.push({
                id: "flux",
                label: "Flux de soudage",
                quantite: 0,
                ratio: 0,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout: 0,
                montant: 0,
              });
            }
          }
          //Fin Fils/flux
          if (this.diametre_mm) {
            let s = (Math.PI * this.diametre_mm * this.linaire_ml) / 1000;
            if (this.ext) {
              let st = this.stocks.findIndex((elm) => elm.id == "pe");
              let st_pe = this.stocks[st].value;
              let ct_pe = this.stocks[st].cout;
              st = this.stocks.findIndex((elm) => elm.id == "adh");
              let st_adh = this.stocks[st].value;
              let ct_adh = this.stocks[st].cout;
              st = this.stocks.findIndex((elm) => elm.id == "gr_ext");
              let st_gr_ext = this.stocks[st].value;
              let ct_gr_ext = this.stocks[st].cout;
              st = this.stocks.findIndex((elm) => elm.id == "poudre");
              let st_poudre = this.stocks[st].value;
              let ct_poudre = this.stocks[st].cout;
              let j = this.ratios.findIndex((elm) => elm.id == "ratio_gr_ext");
              let ratio_gr_ext =
                this.ratios[j].value + " " + this.ratios[j].label;
              let gr = (s * this.ratios[j].value) / 1000;
              j = this.ratios.findIndex((elm) => elm.id == "ratio_pe");
              let ratio_pe = this.ratios[j].value + " " + this.ratios[j].label;
              let pe = (s * this.ratios[j].value) / 1000;
              j = this.ratios.findIndex((elm) => elm.id == "ratio_adh");
              let ratio_adh = this.ratios[j].value + " " + this.ratios[j].label;
              let adhesif = (s * this.ratios[j].value) / 1000;
              j = this.ratios.findIndex((elm) => elm.id == "ratio_poudre");
              let ratio_poudre =
                this.ratios[j].value + " " + this.ratios[j].label;
              let poudre = (s * this.ratios[j].value) / 1000;
              let gr_ach = gr - st_gr_ext > 0 ? gr - st_gr_ext : 0;
              let pe_ach = pe - st_pe > 0 ? pe - st_pe : 0;
              let adhesif_ach = adhesif - st_adh > 0 ? adhesif - st_adh : 0;
              let poudre_ach = poudre - st_poudre > 0 ? poudre - st_poudre : 0;
              let gr_stk = gr - gr_ach;
              let pe_stk = pe - pe_ach;
              let adhesif_stk = adhesif - adhesif_ach;
              let poudre_stk = poudre - poudre_ach;
              let cout_pe = this.couts.find((elm) => elm.id == "pe").cout;
              this.intrants_list.push({
                id: "pe",
                label: "PEHD",
                quantite: pe,
                ratio: ratio_pe,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout: (pe_ach * cout_pe + pe_stk * ct_pe) / pe,
                montant: pe_ach * cout_pe + pe_stk * ct_pe,
              });
              tot_charge = tot_charge + pe_ach * cout_pe * taux_ligne;
              let cout_adh = this.couts.find((elm) => elm.id == "adh").cout;
              this.intrants_list.push({
                id: "adhesif",
                label: "Adhesif",
                quantite: adhesif,
                ratio: ratio_adh,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout: (adhesif_ach * cout_adh + adhesif_stk * ct_adh) / adhesif,
                montant: adhesif_ach * cout_adh + adhesif_stk * ct_adh,
              });
              tot_charge = tot_charge + adhesif_ach * cout_adh * taux_ligne;
              let cout_poudre = this.couts.find(
                (elm) => elm.id == "poudre"
              ).cout;
              this.intrants_list.push({
                id: "poudre",
                label: "Epoxy Poudre",
                quantite: poudre,
                ratio: ratio_poudre,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout:
                  (poudre_ach * cout_poudre + poudre_stk * ct_poudre) / poudre,
                montant: poudre_ach * cout_poudre + poudre_stk * ct_poudre,
              });
              tot_charge = tot_charge + poudre_ach * cout_poudre * taux_ligne;
              let cout_gr_ext = this.couts.find(
                (elm) => elm.id == "gr_ext"
              ).cout;
              this.intrants_list.push({
                id: "gr_ext",
                label: "Grenaille Ext",
                quantite: gr,
                ratio: ratio_gr_ext,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout: (gr_ach * cout_gr_ext + gr_stk * ct_gr_ext) / gr,
                montant: gr_ach * cout_gr_ext + gr_stk * ct_gr_ext,
              });
              tot_charge = tot_charge + gr_ach * cout_gr_ext * taux_ligne;
            } else {
              this.intrants_list.push({
                id: "pe",
                label: "PEHD",
                quantite: 0,
                ratio: 0,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout: 0,
                montant: 0,
              });
              this.intrants_list.push({
                id: "adhesif",
                label: "Adhesif",
                quantite: 0,
                ratio: 0,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout: 0,
                montant: 0,
              });
              this.intrants_list.push({
                id: "poudre",
                label: "Epoxy Poudre",
                quantite: 0,
                unit: "Tonne",
                ratio: 0,
                type: "Matière",
                color: "green",
                cout: 0,
              });
              this.intrants_list.push({
                id: "gr_ext",
                label: "Grenaille Ext",
                quantite: 0,
                ratio: 0,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout: 0,
                montant: 0,
              });
            }
            if (this.int) {
              let st = this.stocks.findIndex(
                (elm) =>
                  elm.id == (this.secteur == 1 ? "peint_gas" : "peint_eau")
              );
              let st_peint = this.stocks[st].value;
              let ct_peint = this.stocks[st].cout;
              st = this.stocks.findIndex((elm) => elm.id == "gr_int");
              let st_gr_int = this.stocks[st].value;
              let ct_gr_int = this.stocks[st].cout;
              let j = this.ratios.findIndex((elm) => elm.id == "ratio_gr_int");
              let ratio_gr_int =
                this.ratios[j].value + " " + this.ratios[j].label;
              let gr = (s * this.ratios[j].value) / 1000;
              let t =
                this.secteur == 1 ? "ratio_peinture_gas" : "ratio_peinture_eau";

              j = this.ratios.findIndex((elm) => elm.id == t);
              let ratio_peinture =
                this.ratios[j].value + " " + this.ratios[j].label;
              let peinture = (s * this.ratios[j].value) / 1000;
              let gr_ach = gr - st_gr_int > 0 ? gr - st_gr_int : 0;
              let peinture_ach =
                peinture - st_peint > 0 ? peinture - st_peint : 0;
              let gr_stk = gr - gr_ach;
              let peinture_stk = peinture - peinture_ach;
              let cout_peinture = this.couts.find(
                (elm) =>
                  elm.id == (this.secteur == 1 ? "peint_gas" : "peint_eau")
              ).cout;
              this.intrants_list.push({
                id: "peinture",
                label: "Epoxy Liquide",
                quantite: peinture,
                ratio: ratio_peinture,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout:
                  (peinture_ach * cout_peinture + peinture_stk * ct_peint) /
                  peinture,
                montant: peinture_ach * cout_peinture + peinture_stk * ct_peint,
              });
              tot_charge = tot_charge + gr_ach * cout_peinture * taux_ligne;
              let cout_gr_int = this.couts.find(
                (elm) => elm.id == "gr_int"
              ).cout;
              this.intrants_list.push({
                id: "gr_int",
                label: "Grenaille Int",
                quantite: gr,
                ratio: ratio_gr_int,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout: (gr_ach * cout_gr_int + gr_stk * ct_gr_int) / gr,
                montant: gr_ach * cout_gr_int + gr_stk * ct_gr_int,
              });
              tot_charge = tot_charge + peinture_ach * cout_gr_int * taux_ligne;
            } else {
              this.intrants_list.push({
                id: "peinture",
                label: "Epoxy Liquide",
                quantite: 0,
                ratio: 0,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout: 0,
                montant: 0,
              });
              this.intrants_list.push({
                id: "gr_int",
                label: "Grenaille Int",
                quantite: 0,
                ratio: 0,
                unit: "Tonne",
                type: "Matière",
                color: "green",
                cout: 0,
                montant: 0,
              });
            }

            if (this.delai2 > 0) {
              let j = this.frais.findIndex((elm) => elm.id == "frais_pers");
              if (this.frais[j].value > 0) {
                this.intrants_list.push({
                  id: "frais_pers",
                  label: "Frais du Personnel",
                  quantite: parseFloat(this.delai2),
                  unit: "Mois",
                  type: "Frais",
                  color: "blue",
                  cout: this.frais[j].value,
                  montant: parseFloat(this.delai2) * this.frais[j].value,
                });
                tot_charge =
                  tot_charge +
                  parseFloat(this.delai2) * this.frais[j].value * taux_ligne;
              }
              j = this.frais.findIndex((elm) => elm.id == "cons_pdr");
              if (this.frais[j].value > 0) {
                this.intrants_list.push({
                  id: "cons_pdr",
                  label: "Consommables et PDR",
                  quantite: this.linaire_ml,
                  unit: "ML",
                  type: "Frais",
                  color: "blue",
                  cout: this.frais[j].value,
                  montant: this.linaire_ml * this.frais[j].value,
                });
                tot_charge =
                  tot_charge +
                  this.linaire_ml * this.frais[j].value * taux_ligne;
              }
              j = this.frais.findIndex((elm) => elm.id == "service");
              if (this.frais[j].value > 0) {
                this.intrants_list.push({
                  id: "service",
                  label: "Services",
                  quantite: parseFloat(this.delai2),
                  unit: "Mois",
                  type: "Frais",
                  color: "blue",
                  cout: this.frais[j].value,
                  montant: parseFloat(this.delai2) * this.frais[j].value,
                });
                tot_charge =
                  tot_charge +
                  parseFloat(this.delai2) * this.frais[j].value * taux_ligne;
              }
              j = this.frais.findIndex((elm) => elm.id == "energie_fluid");
              if (this.frais[j].value > 0) {
                this.intrants_list.push({
                  id: "energie_fluid",
                  label: "Energies et fluides",
                  quantite: this.linaire_ml,
                  unit: "ML",
                  type: "Frais",
                  color: "blue",
                  cout: this.frais[j].value,
                  montant: this.linaire_ml * this.frais[j].value,
                });
                tot_charge =
                  tot_charge +
                  this.linaire_ml * this.frais[j].value * taux_ligne;
              }
              if (this.transport_tube) {
                this.intrants_list.push({
                  id: "transport_tube",
                  label: "Transport tubes",
                  quantite: this.linaire_ml,
                  unit: "ML",
                  type: "Frais",
                  color: "blue",
                  cout: this.cout_transport_tube,
                  montant: this.linaire_ml * this.cout_transport_tube,
                });
                tot_charge =
                  tot_charge +
                  this.linaire_ml * this.cout_transport_tube * taux_ligne;
              } else {
                this.intrants_list.push({
                  id: "transport_tube",
                  label: "Transport tubes",
                  quantite: 0,
                  unit: "ML",
                  type: "Frais",
                  color: "blue",
                  cout: 0,
                  montant: 0,
                });
              }

              if (tot_charge > 0 && this.taux_credit > 0) {
                this.intrants_list.push({
                  id: "Frais_financier",
                  label: "Frais financier",
                  quantite: tot_charge,
                  unit: "Taux",
                  type: "Frais",
                  color: "blue",
                  cout: this.taux_credit,
                  montant: tot_charge,
                });
              }
              j = this.frais.findIndex((elm) => elm.id == "amortissement");
              if (this.frais[j].value > 0) {
                this.intrants_list.push({
                  id: "amortis",
                  label: "Dotations amortissements",
                  quantite: parseFloat(this.delai2),
                  unit: "Mois",
                  type: "Frais",
                  color: "blue",
                  cout: this.frais[j].value,
                  montant: parseFloat(this.delai2) * this.frais[j].value,
                });
              }
              if (this.caut_soumis > 0) {
                let c =
                  this.intrants_list.reduce(
                    (a, b) => a + (b["montant"] || 0),
                    0
                  ) *
                  (1 + (this.marge ? this.marge : 0) / 100) *
                  (this.caut_soumis / 100);
                this.intrants_list.push({
                  id: "caut_soumis",
                  label: "Frais de caution de soumission",
                  quantite: c,
                  unit: "Taux",
                  type: "Frais",
                  color: "blue",
                  cout: 3,
                  montant: (parseFloat(c) * 3) / 100,
                });
              } else {
                this.intrants_list.push({
                  id: "caut_soumis",
                  label: "Frais de caution de soumission",
                  quantite: 0,
                  unit: "Taux",
                  type: "Frais",
                  color: "blue",
                  cout: 0,
                  montant: 0,
                });
              }
              if (this.caut_rest_av > 0) {
                let c =
                  this.intrants_list.reduce(
                    (a, b) => a + (b["montant"] || 0),
                    0
                  ) *
                  (1 + (this.marge ? this.marge : 0) / 100) *
                  (this.caut_rest_av / 100);
                let nb_trim = Math.ceil((this.delai2 * 50) / 100 / 3);
                let m = (c * nb_trim * 3) / 100;
                this.intrants_list.push({
                  id: "caut_rest_av",
                  label: "Frais de caution de Restitution avance",
                  quantite: c,
                  unit: "Taux",
                  type: "Frais",
                  color: "blue",
                  cout: 3,
                  montant: m,
                });
              } else {
                this.intrants_list.push({
                  id: "caut_rest_av",
                  label: "Frais de caution de Restitution avance",
                  quantite: 0,
                  unit: "Taux",
                  type: "Frais",
                  color: "blue",
                  cout: 0,
                  montant: 0,
                });
              }
              if (this.caut_bon_exe > 0) {
                let c =
                  this.intrants_list.reduce(
                    (a, b) => a + (b["montant"] || 0),
                    0
                  ) *
                  (1 + (this.marge ? this.marge : 0) / 100) *
                  (this.caut_bon_exe / 100);
                let nb_trim = Math.ceil(this.delai2 / 3);
                let m = (c * nb_trim * 3) / 100 + ((c / 2) * 8 * 3) / 100;
                this.intrants_list.push({
                  id: "caut_bon_exe",
                  label: "Frais de caution de bonne execution",
                  quantite: c,
                  unit: "Taux",
                  type: "Frais",
                  color: "blue",
                  cout: 3,
                  montant: m,
                });
              } else {
                this.intrants_list.push({
                  id: "caut_bon_exe",
                  label: "Frais de caution de bonne execution",
                  quantite: 0,
                  unit: "Taux",
                  type: "Frais",
                  color: "blue",
                  cout: 0,
                  montant: 0,
                });
              }
              if (this.caut_pen_ret > 0) {
                let c =
                  this.intrants_list.reduce(
                    (a, b) => a + (b["montant"] || 0),
                    0
                  ) *
                  (1 + (this.marge ? this.marge : 0) / 100) *
                  (this.caut_pen_ret / 100);
                let m = (c * this.nb_trimest * 3) / 100;
                this.intrants_list.push({
                  id: "caut_pen_ret",
                  label: "Frais de caution de Penalités de retard",
                  quantite: c,
                  unit: "Taux",
                  type: "Frais",
                  color: "blue",
                  cout: 3,
                  montant: m,
                });
              } else {
                this.intrants_list.push({
                  id: "caut_pen_ret",
                  label: "Frais de caution de Penalités de retard",
                  quantite: 0,
                  unit: "Taux",
                  type: "Frais",
                  color: "blue",
                  cout: 0,
                  montant: 0,
                });
              }
              this.cout_revient = (
                this.intrants_list.reduce(
                  (a, b) => a + (b["montant"] || 0),
                  0
                ) / this.linaire_ml
              ).toFixed(2);
              let m = (this.marge ? this.marge : 0) / 100;
              m = m + 1;
              this.prixvente = (this.cout_revient * m).toFixed(2);
              this.mk4++;
            }
          }
          this.klist++;
        }
      }
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
    read_simulation() {
      this.secteur = this.state == 0 ? null : this.simulation.secteur;
      this.unite = this.state == 0 ? null : this.simulation.unite;
      this.unite_code = this.state == 0 ? null : this.simulation.unite_code;
      this.unite_name = this.state == 0 ? null : this.simulation.unite_name;
      this.vitesses1 = this.state == 0 ? null : this.simulation.vitesses1;
      this.vitesses2 = this.state == 0 ? null : this.simulation.vitesses2;
      // this.acier = this.simulation.bobines.acier;
      this.money = this.simulation.bobines.money;
      this.taux_change = this.simulation.bobines.taux_change;
      this.poids = this.simulation.bobines.poids;
      this.frais = this.simulation.bobines.frais;
      this.modalite = this.simulation.bobines.modalite;
      this.taux_lc = this.simulation.bobines.taux_lc;
      this.couts = this.simulation.couts;
      this.stocks = this.simulation.stocks;
      this.intrants_list = this.simulation.intrants_list;
      this.frais = this.simulation.frais;
      this.machines = this.simulation.machines;
      this.ratios = this.simulation.ratios;
      this.mam = this.simulation.simul_result.mam;
      this.epaisseur_mm =
        this.state == 0 ? null : this.simulation.simul_result.epaisseur_mm;
      this.diametre_mm =
        this.state == 0 ? null : this.simulation.simul_result.diametre_mm;
      this.diametre_inch =
        this.state == 0 ? null : this.simulation.simul_result.diametre_inch;
      this.bobine = this.state == 0 ? true : this.simulation.bobine;
      this.fil_flux = this.state == 0 ? null : this.simulation.fil_flux;
      this.ext = this.state == 0 ? null : this.simulation.ext;
      this.int = this.state == 0 ? null : this.simulation.int;
      this.transport_tube =
        this.state == 0 ? false : this.simulation.transport_tube;

      this.delai = this.state == 0 ? null : this.simulation.simul_result.delai;
      this.delai2 =
        this.state == 0 ? null : this.simulation.simul_result.delai2;
      this.largeur_mm =
        this.state == 0 ? 1200 : this.simulation.simul_result.largeur_mm;
      this.linaire_ml =
        this.state == 0 ? null : this.simulation.simul_result.linaire_ml;
      this.cout_revient =
        this.state == 0 ? null : this.simulation.simul_result.cout_revient;
      this.marge = this.state == 0 ? 10 : this.simulation.simul_result.marge;
      this.prixvente =
        this.state == 0 ? null : this.simulation.simul_result.prixvente;

      this.caut_soumis =
        this.state == 0 ? 0 : this.simulation.cautions.caut_soumis;
      this.caut_rest_av =
        this.state == 0 ? 0 : this.simulation.cautions.caut_rest_av;
      this.caut_bon_exe =
        this.state == 0 ? 0 : this.simulation.cautions.caut_bon_exe;
      this.caut_pen_ret =
        this.state == 0 ? 0 : this.simulation.cautions.caut_pen_ret;
      this.nb_trimest =
        this.state == 0 ? 1 : this.simulation.cautions.nb_trimest;
      this.klist++;
      this.mk3++;
      this.mk4++;
    },
    async save_simulation() {
      const d = new Date().toJSON().slice(0, 10);
      this.simulation = {};
      this.simulation.date = d;
      this.simulation.bobine = this.bobine;
      this.simulation.fil_flux = this.fil_flux;
      this.simulation.ext = this.ext;
      this.simulation.int = this.int;
      this.simulation.transport_tube = this.transport_tube;
      this.simulation.secteur = this.secteur;
      this.simulation.unite = this.unite;
      this.simulation.unite_code = this.unite_code;
      this.simulation.unite_name = this.unite_name;
      this.simulation.vitesses1 = this.vitesses1;
      this.simulation.vitesses2 = this.vitesses2;
      // Bobines
      let bobs = {};
      //  bobs.acier = this.acier;
      bobs.money = this.money;
      bobs.taux_change = this.taux_change;
      bobs.poids = this.poids;
      bobs.frais = this.frais;
      bobs.modalite = this.modalite;
      bobs.taux_lc = this.taux_lc;
      //
      this.simulation.bobines = bobs;
      this.simulation.couts = this.couts;
      this.simulation.stocks = this.stocks;
      this.simulation.intrants_list = this.intrants_list;
      this.simulation.frais = this.frais;
      this.simulation.machines = this.machines;
      this.simulation.ratios = this.ratios;
      //cautions
      let cautions = {};
      cautions.caut_soumis = this.caut_soumis;
      cautions.caut_rest_av = this.caut_rest_av;
      cautions.caut_bon_exe = this.caut_bon_exe;
      cautions.caut_pen_ret = this.caut_pen_ret;
      cautions.nb_trimest = this.nb_trimest;
      this.simulation.cautions = cautions;
      //result
      let simul_result = {};
      simul_result.mam = this.mam;
      simul_result.epaisseur_mm = this.epaisseur_mm;
      simul_result.diametre_mm = this.diametre_mm;
      simul_result.diametre_inch = this.diametre_inch;
      simul_result.delai = this.delai;
      simul_result.delai2 = this.delai2;
      simul_result.largeur_mm = this.largeur_mm;
      simul_result.linaire_ml = this.linaire_ml;
      simul_result.cout_revient = this.cout_revient;
      simul_result.marge = this.marge;
      simul_result.prixvente = this.prixvente;
      //
      this.simulation.simul_result = simul_result;

      let r = await this.maj(UPSERT_SIMULATION, {
        simulation: {
          id: this.sid,
          intitule: this.project_name,
          details: JSON.stringify(this.simulation),
        },
      });
      if (r) this.get_data();
    },
    add_project() {
      this.isNew_project = true;
    },
    close_project() {
      this.isNew_project = false;
    },
    save_project() {
      if (this.projects.findIndex((x) => x.intitule == this.project_name) > 0) {
        this.snackbar_text = "Le nom du projet existe déja !";
        this.snackbar_color = "error";
        this.snackbar = true;
      } else {
        this.projects.push(this.project_name);
        this.isNew_project = false;
      }
    },
    RowAdd() {
      this.project_name = null;
      this.sel_simulation = {};
      this.sid = -1;
      this.state = 0;
      this.read_simulation();
    },
    RowSelect(item) {
      this.sel_simulation = item;
      this.project_name = this.sel_simulation.intitule;
      this.simulation = JSON.parse(this.sel_simulation.details);
      this.sid = item.id;
      this.state = 1;
      this.read_simulation();
    },
    async requette(query, v) {
      let r;
      this.art_loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.art_loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.art_loading = false;
        });
      return r;
    },
    async AgentChange(item) {
      this.agent = item;
    },
  },
};
</script>
